.game{
  background-color: #222;
  .boardWrap{
    position: relative;
    width:100vw;
    height:100vh;
    margin:0 auto;
    overflow:hidden;
    //background-color: #333;
  }
  .fader{
    position: fixed;
    width:100%;
    height:100%;
    top:0;
    left:0;
    background-color: #222;
    opacity:0;
    transition: .3s opacity ease-out;
    &.active{
      opacity:1;
    }
  }
  .hud{
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
  }
  .board{
    position: absolute;
    top:0;
    left:0;
    &.anim{
      transition: .2s top ease-in-out, .2s left ease-in-out;
    }
  }
}

.dummyBtn{
  position:absolute;
  top:0;
  left:0;
  width:1px;
  height:1px;
  opacity:0;
  pointer-events: none;
}

.instrux{
  @media(max-width:910px){
    display:none;
  }
  position:fixed;
  bottom:10px;
  left:0;
  width:100%;
  font-size:16px;
  text-align:right;
  color:white;
  text-shadow: 1px 1px 0 rgba(0,0,0,.8);
  .inner{
    padding-right:100px;
  }
}
