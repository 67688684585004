.dialogueModal{
  padding:30px;
  .title{
    h2{
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight:800;
    }
    .handleDialogClose{

    }
  }
  .portrait{
    width:100%;
    height:auto;
    @media (max-width:768px){
      display:none;
    }
  }
  .quoteWrap{
    padding:0;
    overflow:visible;
  }
  .quoteCol{
    padding:20px;
  }
  .content{
    line-height:1.3;
  }
  .teleportOption{
    width:100%;
    img{
      max-width:30px;
      height:auto;
      margin-right:10px;
    }
  }
  .quote{
    font-size:30px;
    line-height:1.2;
    position: relative;
    background: #ddd;
    border-radius: .4em;
    padding:10px;
    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      width: 0;
      height: 0;
      border: 20px solid transparent;
      border-left-color: #ddd;
      border-right: 0;
      border-bottom: 0;
      margin-top: -10px;
      margin-right: -20px;
    }
  }
  .options{
    padding:20px;
    .optionButton{
      text-transform: none;
      width:100%;
    }
  }
}

.vCenter{
  display: flex;
  align-items: center;
}


