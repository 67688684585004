.item{
  position:absolute;
  //&.coins-small{
  //  animation: pulseScale .5s ease-in-out 0s infinite alternate;
  //}
  &.balloony1,
  &.balloony2,
  &.balloony3,
  &.balloony4{
    animation: pulseJump 1s ease-in-out 0s infinite alternate;
  }
  //&.coins-large{
  //  animation: pulseJump .5s ease-in-out 0s infinite alternate;
  //}
  //&.key1{
  //  animation: swingSmall .5s ease-in-out 0s infinite alternate;
  //}
}
.bowlGuage{
  position: absolute;
  left:25%;
  top:65%;
  width:50%;
  height:20px;
  text-align:center;
  .guage{
    position:absolute;
    left:0;
    top:-3px;
    width:100%;
    height:10%;
    border-left: 1px solid #444;
    border-right: 1px solid #444;
    height:8px;
    .guageBit{
      position:absolute;
      top:2px;
      width:8%;
      height:4px;
      margin-right:2%;
      opacity:0;
      &.active{
        opacity:1;
      }
    }
  }
  .guageE{
    position:absolute;
    left:0;
    top:30%;
  }
  .guageF{
    position:absolute;
    right:0;
    top:30%;
  }
  font-size:18px;
  @media (max-width: 1000px){
    font-size:16px;
  }
  @media (max-width: 768px){
    font-size:14px;
  }
}

@keyframes swingSmall{
  from {
    transform: rotate(-10deg);
  }
  to {
    transform: rotate(10deg);
  }
}
@keyframes pulseScale{
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}
@keyframes pulseJump{
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-1vw);
  }
}
