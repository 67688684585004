.editorRoot{
  margin-top:75px;
}

.itemModal{
  background-color: white;
  border-radius:5px;
  box-shadow: 3px 3px 12px rgba(0,0,0,.4);
  position:absolute;
  opacity:0;
  right:0;
  bottom:0;
  z-index: 100;
  pointer-events: none;
  &.active{
    opacity:1;
    pointer-events: all;
  }
}
.areaEditor{
  position:relative;
  margin-top:50px;
  margin-left:50px;
  margin-bottom:50px;
  .editorItem {
    position: absolute;
    width: 50px;
    height: 50px;
    cursor: pointer;
    pointer-events: none;
    &.active{
      pointer-events: all;
    }
  }
  .editorTile{
    position:absolute;
    width:50px;
    height:50px;
    cursor: pointer;
    border:1px solid #bbb;
    .tileMain{
      position: absolute;
      top:0;
      left:0;
      width:50px;
      height:50px;
      display:flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
      &.active{
        pointer-events: all;
      }
    }
    .btnWallNorth{
      position:absolute;
      width:50px;
      height:10px;
      top: -5px;
      left: 0;
      cursor: pointer;
      .door{
        position:absolute;
        width:10px;
        height:10px;
        top:0;
        left:20px;
        background-color: red;
        &.open{
          background-color: white;
        }
      }
    }
    .btnWallSouth{
      position:absolute;
      width:50px;
      height:10px;
      bottom: -5px;
      left: 0;
      cursor: pointer;
    }
    .btnWallWest{
      position:absolute;
      width:10px;
      height:50px;
      top: 0;
      left: -5px;
      cursor: pointer;
      .door{
        position:absolute;
        width:10px;
        height:10px;
        top:20px;
        left:0px;
        background-color: red;
        &.open{
          background-color: white;
        }
      }
    }
    .btnWallEast{
      position:absolute;
      width:10px;
      height:50px;
      top: 0;
      right: -5px;
      cursor: pointer;
    }
  }

}

.textArea{
  width:100%;
  min-height:200px;
}

.modal{
  position:fixed;
  width:100%;
  height:100%;
  top:0;
  left:0;
  background-color: rgba(0,0,0,.7);
  z-index:999;
  opacity: 0;
  pointer-events: none;
  transition: .1s opacity ease-out;
  &.active{
    opacity: 1;
    pointer-events: all;
  }
  .modalStage{
    border: 2px solid #444;
    border-radius:6px;
    background-color: white;
    padding:30px;
    max-width:400px;
    margin:100px auto;
  }
}
.btnWallSelect{
  margin-right:7px;
  margin-bottom:10px;
  &:hover,
  &.active{
    background-color: #333!important;
    color:white!important;
  }
}
.btnItemSelect{
  margin-right:7px;
  img{
    border:4px solid #eee;
    width:50px;
    height:50px;
  }
  &.active{
    img{
      border-color:red;
    }
  }
}

.btnTileSelect{
  margin-right:7px;
  img{
    border:4px solid #eee;
    width:50px;
    height:50px;
  }
  &.active{
    img{
      border-color:red;
    }
  }
}

.floatingModal{
  position:absolute;
  background:#fff;
  right:0;
  top:80px;
  width:500px;
  border-radius:6px;
  border: 2px solid #bbb;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  .tabs{
    background-color: #eee;
    border-bottom:1px solid #555;
  }

}
.dragHandle{
  width:100%;
  height:20px;
  cursor: move;
  background-color: #ddd;
  border-bottom:1px solid #555;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc0JyBoZWlnaHQ9JzQnPgogIDxyZWN0IHdpZHRoPSc0JyBoZWlnaHQ9JzQnIGZpbGw9JyNkZGQnIC8+CiAgPGNpcmNsZSBjeD0iMSIgY3k9IjEiIHI9IjEiIGZpbGw9IiM4ODgiLz4KPC9zdmc+");
  background-repeat: repeat;
  z-index:2;
}
