.gameOver{
  .title{
    background-image: url(/images/mac-and-cheese.jpg);
    background-size:cover;
    background-repeat: no-repeat;
    text-align:center;
    h2{
      font-weight:800;
      font-size:50px;
      padding:20px 0;
    }
  }
  .content{
    text-align:center;
    font-size:20px;
    line-height:1.3;
    padding:20px 30px;
  }
  .actions{
    padding:20px;
    .btn{
      background-color: #444;
      color: yellow;
      font-weight:bold;
      font-size:24px;
    }
  }
}
